import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Sections/Header"
import Footer from "./Sections/Footer"

const Layout = ({ isHomePage, isOverflowHidden, isBlogPost, children }) => {

  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <div className="global-container">
        <Header className="container"/>
        <main className="main_static" data-blog-template={isBlogPost} data-overflow={isOverflowHidden}>{children}</main>
        <Footer className="container"/>
      </div>
    </div>
  )
}

export default Layout
