import React, { useState } from "react"
import { motion } from "framer-motion"
import { StaticQuery, graphql } from "gatsby"

export default function NavLinks (props) {
  const animateFrom = { opacity: 0, y: -40 }
  const animateTo = { opacity: 1, y: 0 }

  const [isActive, setActive] = useState(false)

  const toggleSubMenu = () => setActive(!isActive);
  
  return (
    <StaticQuery
    query={graphql`
      {
        wpMenu {
          id
          menuItems {
            nodes {
              id
              url
              label
              parentId
              childItems {
                nodes {
                  id
                  label 
                  url
                  parentId
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const linksList = Object.values(
        data.wpMenu.menuItems.nodes
      ).filter(el => el !== null && el.parentId === null)
      const allLinks = linksList.map((uniquelink,index) => {
            return (
            <motion.li key={uniquelink.id}
            initial={animateFrom}
            animate={animateTo}
            transition={{ ease: "easeOut", duration:((index + 1 )/ 2) }}
          >{ uniquelink.childItems.nodes.length > 0 ? (
            <>
            <button onClick={toggleSubMenu}>{uniquelink.label}</button>
              <ul className={isActive ? "submenu-ul open" : "submenu-ul"}>
                {uniquelink.childItems.nodes.map(submenu => {
                  return(
                  <li key={submenu.id}>
                    <a href={submenu.url}>{submenu.label}</a>
                  </li>
                  )
                })}
              </ul>
            </>
          ) : <a href={uniquelink.url}>{uniquelink.label}</a> 
          }
          </motion.li>
          )
      })
      return(
        <nav className="main-nav-container">
          <ul className="navlinks_ul">
            {allLinks}
          </ul></nav>
          )
        }}
    />
  )
}


