import React, { useState, useRef } from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import CloseX from "../../Images/Close_menu.svg"


const Newsletter = ({showNewsletter, setShowNewsletter}) => {
    const [email, setEmail] = useState('')
    const [response, setResponse] = useState(null)
    
    const newsletterRef = useRef();
    const closeNewsletter = e => {
        if (newsletterRef.current === e.target){
            setShowNewsletter(false)
        }
        window.sessionStorage.setItem('newsletter', 'true')
        setResponse(null);  
    };

   const _handleSubmit = e => {
        e.preventDefault();
        addToMailchimp(email)
        .then(data => {
          setResponse(data)
        })
        .catch(() => {

          })
        }
    return(
        <>
        {showNewsletter ? <div className="backdrop" ref={newsletterRef} onClick={e => closeNewsletter(e)}>
        <div className="modal_container newsletter_modal">
            <img alt="Close"className="close_btn" onClick={() => setShowNewsletter(prev => !prev)} src={CloseX}/>
                <div className="letter">
                    <h1 style={{ letterSpacing: 5 ,marginBottom:0}}>WELCOME</h1>
                    <h4 style={{ marginTop: 20,marginBottom:20 }}>Maria Kardami Design Studio</h4>
                    <p>Sign up for Maria Kardami’s email list to be the first to hear about our new projects and all the upcoming events. </p>
                    <div className="form">
                        <form className="subscribe" onSubmit={e => _handleSubmit(e)}>
                            <input type="email" onChange={event => setEmail(event.target.value)} name="EMAIL" id="mce-EMAIL" className="subscribe-email" placeholder="Email Address" required />
                            <button className="btn_send blog_btn_span" type="submit">Sign Up</button>
                        </form>
                    </div>
                    {response && <p className="thank_you_msg">{response.msg}</p>}
                </div>
            </div>
        </div> : null}
        </>
    )
}

export default Newsletter
