import React from "react"
import Hamburger from "../../Images/Hamburger.svg"
import CloseX from "../../Images/Close_menu.svg"
import NavLinks from "./NavLinks"
import { useState } from "react"

const Navigation = () => {
  const [open, setOpen] = useState(false)

  const onClickFunction = () => setOpen(!open)

  const hamburgerIcon = (
    <img
      src={Hamburger}
      className="hamburger_menu"
      alt="hamburger icon"
    />
  )
  const closeIcon = (
    <img
      src={CloseX}
      transition={{ ease: "easeOut", duration: 1.7 }}
      className="hamburger_menu closing_menu"
      alt="close icon"
    />
  )
  const closeMenu = () => setOpen(false)
  return (
    <div className="navigation">
      <button
        onClick={onClickFunction}
        className="nav_btn_toggle"
      >
        {open ? closeIcon : hamburgerIcon}
      </button>
      {open && <NavLinks isOpen={true} closeMenu={closeMenu} />}
    </div>
  )
}

export default Navigation
