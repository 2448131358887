import React, { useState, useEffect } from "react"
import Newsletter from "../Elements/Newsletter"

const Footer = ({className}) => {
  
  const [showNewsletter, setShowNewsletter] = useState(false)

  useEffect(()=>{
      if(!window.sessionStorage.getItem('newsletter')){
      setTimeout(()=>{
        setShowNewsletter(true)
      }, 5000) 
    }
  }, [])

  const openNewsletter = () =>{
    setShowNewsletter(prev => !prev)
  };
    return(
      <>
      
        <Newsletter showNewsletter={showNewsletter} setShowNewsletter={setShowNewsletter}/>
        <footer className={`footer ${className ? className : ''}`}>
        <span>
          {new Date().getFullYear()} MARIA KARDAMI DESIGN STUDIO, 
          {` `}
          <a className="footer_link" rel="noreferrer" target="_blank"href="https://think-plus.gr/">Developed by Think Plus</a>
          {` `}
          </span>
          <span><button className="footer_link footer_newsletter" onClick={openNewsletter}>NEWSLETTER</button><span className="pipe_footer">|</span><a className="footer_link" href="#">ALL RIGHTS RESERVED</a></span>
        </footer>
        </>
    );
}

export default Footer;