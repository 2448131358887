import React from "react"
import mainLogo from "../../../src/Images/MK_logo_sima.svg"
import brandName from "../../../src/Images/MK_logo.svg"
import Navigation from "../Elements/BurgerMenu"

const Header = ({className}) => {
  return (
    <header className={`header ${className ? className : ''}`}>
      <div>
        <a href="/"><img className="main_logo" src={mainLogo} alt="Maria Kardami" /></a>
      </div>
      <div>
      <a href="/"><img src={brandName} className="logo_name"alt="Maria Kardami" /></a>
      </div>
      <div> 
        <Navigation/>
      </div>
    </header>
  )
}

export default Header